/**
 * Used to display the loading dialog (i.e. block the page when a given action is performed by the user). Use as follows:
 *
 * <div loading="loading"></div>
 *
 * Where the loading attribute is a boolean model object ($scope.loading = true/false) that controls whether to display the loading dialog or not
 */
angular.module('app')
    .directive("loading", function () {
        return {
            restrict: "A",
            scope: {
                loading: '=loading'
            },
            template: '<div id="loading" ng-show="loading" >' +
            '<img ng-src="/static/images/loader.gif"/>' +
            '</div>'
        };
    });
